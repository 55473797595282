@font-face {
  font-family: 'Itau Display Regular';
  src: url('/fonts/ItauDisplay_Rg.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Itau Display Bold';
  src: url('/fonts/ItauDisplay_Bd.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Itau Display XBold';
  src: url('/fonts/ItauDisplay_XBd.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Itau Text Regular';
  src: url('/fonts/ItauText_Bd.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Itau Text Bold';
  src: url('/fonts/ItauText_Bd.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Itau Text XBold';
  src: url('/fonts/ItauText_XBd.ttf') format('truetype');
  font-display: swap;
}
